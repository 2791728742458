import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
        <Link to="/imprint" className="link">
          Imprint
        </Link>
        |
        <Link to="/faq" className="link">
          FAQ
        </Link>
        |
        <Link to="/contact" className="link">
          Contact
        </Link>
    </div>
  );
};

export default Footer;
