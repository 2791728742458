import React, { Suspense, useState, useRef } from 'react';
import { Helmet } from "react-helmet";
const Quiz = React.lazy(() => import('../../components/Quiz')); // Assuming Quiz component is in '../Quiz'


const Homepage = () => {
  const [startQuiz, setStartQuiz] = useState(false);
  const faqRef = useRef(null);

  const handleClick = () => {
    setStartQuiz(true);
  };

  const handleScroll = () => {
    faqRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={`app quiz`}>
      <Helmet>
        <title>Calculate and compensate your lifetime emissions!</title>
        <meta name="description" content="With our free lifetime emissions calculator, you can estimate the impact your lifestyle had on the planet - and find ways to compensate it effectively." />
      </Helmet>
      <div className={`quiz-bg`}></div>
      <div className="screen background">
        {startQuiz ? (
          <Suspense fallback={<div>Loading...</div>}>
            <Quiz />
          </Suspense>
        ) : (
          <>
            <h1 className="heading center">
              Estimate and compensate your <span className="highlight">lifetime emissions!</span>
            </h1>

            <p className="center">
              Every year since birth, you have been emitting emissions that are
              warming the planet. And the majority of those emissions are still in the
              atmosphere.
            </p>
            <h2 className="heading center">
              Let's leave a <span className="highlight">better planet</span> behind.
            </h2>
            <p className="center">
              With our free lifetime emissions calculator, you can estimate the impact
              your lifestyle had on the planet - and find ways to <a href="/compensate">compensate</a>
              {" "}it effectively.
              <br />
              Try it out now, it's free and only takes 1 minute:
            </p>
            <button className="action_btn" onClick={handleClick}>Calculate now!</button>
          </>
        )}
      </div>
       {/* <button className="invisible_button" onClick={handleScroll}>How it works</button> */}
    </div>
  );
}

export default Homepage;