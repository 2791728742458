import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Header from "./components/header";
import Footer from "./components/footer";
import Homepage from "./pages/Homepage";

const Imprint = lazy(() => import("./pages/imprint"));
const FAQ = lazy(() => import("./pages/faq"));
const Compensate = lazy(() => import("./pages/compensate"));
const Contact = lazy(() => import("./pages/contact"));
const Quiz = lazy(() => import("./components/Quiz"));
const QuizResult = lazy(() => import("./components/QuizResult"));

const App = () => {
  return (
    <div className="container">
      <Header />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/quiz_result" element={<QuizResult />} />
          <Route path="/compensate" element={<Compensate />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Homepage />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
};

export default App;
