import React from "react";
// import logo from "../assets/le-logo.png";

const Header = () => {
  return (
    <div className="header">
      <a href="/">
        <h1 className="title">lifetime-emissions.com</h1>
      </a>
    </div>
  );
};

export default Header;
